@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Satoshi';
  font-style: 'normal';
  font-weight: 300;
  src: url('../public/fonts/Satoshi-Light.woff2');
}

@font-face {
  font-family: 'Satoshi';
  font-style: 'normal';
  font-weight: 400;
  src: url('../public/fonts/Satoshi-Regular.woff2');
}

@font-face {
  font-family: 'Satoshi';
  font-style: 'normal';
  font-weight: 500;
  src: url('../public/fonts/Satoshi-Medium.woff2');
}